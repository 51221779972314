<template>
  <Layout>
    <Layout.Content>
      <Row align="middle" justify="center" style="height: 100vh">
        <Col>
          <Row align="middle" justify="center">
            <img alt="brgy logo image" src="@/assets/logo.png">
          </Row>
          <Row align="middle" justify="center">
            <h1>Brgy. Pagatpat Portal</h1>
          </Row>
          <Row align="middle" justify="center">
            <h4>SITE IS UNDER DEVELOPMENT</h4>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  </Layout>
</template>

<script>
import {Layout, Row, Col} from "ant-design-vue"

export default {
  name: "App",
  components: {
    Layout,
    Row,
    Col
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
</style>
